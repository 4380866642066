<template>
  <v-sheet
    id="login"
    tile
    class="sheet d-flex flex-column justify-center align-center text-center pa-4"
    v-show="show"
  >
    <v-scroll-y-transition>
      <div
        class="jumbo mb-6 d-flex flex-column align-center justify-space-around"
      >
        <m-btn-long
          depressed
          icon
          :ripple="false"
          :duration="3000"
          :progress="false"
          color="primary"
          press-color="primary darken-1"
          min-width="96"
          min-height="96"
          content-class="my-2"
          class="btn"
          @press="toggleGhost()"
        >
          <icon-base
            width="37"
            height="43"
            name="hive"
            color="primary"
            class="hive"
          >
            <brand-symbol />
          </icon-base>
        </m-btn-long>

        <v-expand-transition>
          <icon-base
            v-if="largeHeading"
            width="126"
            height="26"
            name="Mobees"
            color="primary"
            class="logo mb-4"
          >
            <brand-logo />
          </icon-base>
        </v-expand-transition>

        <h6
          v-html="heading" 
          class="text-h5 heading mt-6 mb-2"
        />
      </div>
    </v-scroll-y-transition>

    <v-scroll-y-transition>
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        class="login-form"
      >
        <v-expand-transition>
          <v-text-field
            v-if="!firstAccess&&input.cpf.exists==null&&(!input.ghost.toggle||ghosted)"
            v-model="cpf"
            v-mask="input.cpf.mask"
            :loading="input.cpf.loading"
            :disabled="input.cpf.disabled||passwordRecovery"
            label="CPF"
            required
            hint="Apenas números - sem pontos e hífen"
            class="control my-4"
            id="username"
            ref="cpf"
            type="tel"
            hide-details
            :append-icon="cpf!=null&&!passwordRecovery ? icons.mdiClose : ''"
            @click:append="resetForm"
            outlined
            @keyup.enter="handleForm"
          >
            <template v-slot:prepend-inner>
              <v-icon 
                v-if="ghosted"
                class="mr-2 text--secondary"
              >
                {{ icons.ghost }}
              </v-icon>
            </template>
          </v-text-field>
        </v-expand-transition>
        <v-expand-transition>
          <v-text-field
            v-if="input.ghost.toggle&&!ghosted"
            v-model="input.ghost.username"
            :loading="input.ghost.loading"
            :disabled="input.ghost.disabled"
            label="Usuário"
            required
            class="control my-4"
            id="username"
            ref="cpf"
            hide-details
            @click:append="resetForm"
            outlined
            @keyup.enter="handleForm"
          />
        </v-expand-transition>

        <v-expand-transition>
          <v-text-field
            v-show="input.cpf.exists==false"
            v-model="email"
            :loading="input.email.loading"
            :disabled="input.email.disabled"
            label="Email"
            required
            hint="Digite o email cadastrado"
            ref="email"
            type="email"
            class="control my-4"
            hide-details
            :append-icon="email!=null ? icons.mdiClose : ''"
            @click:append="resetForm"
            outlined
            @keyup.enter="handleForm"
          />
        </v-expand-transition>

        <v-expand-transition>
          <v-text-field
            v-show="firstAccess||passwordRecovery"
            v-model="input.newPassword.new"
            :append-icon="input.newPassword.visible ? icons.mdiEye : icons.mdiEyeOff"
            :type="input.newPassword.visible ? 'text' : 'password'"
            :disabled="input.newPassword.disabled"
            label="Criar Senha"
            hide-details
            required
            outlined
            ref="newPassword"
            class="control my-4"
            @click:append="input.newPassword.visible = !input.newPassword.visible"
          />
        </v-expand-transition>
        <v-expand-transition>
          <v-text-field
            v-show="firstAccess||passwordRecovery"
            v-model="input.newPassword.confirm"
            :append-icon="input.newPassword.visible ? icons.mdiEye : icons.mdiEyeOff"
            :type="input.newPassword.visible ? 'text' : 'password'"
            :disabled="input.newPassword.disabled"
            @click:append="input.newPassword.visible = !input.newPassword.visible"
            label="Confirmar Senha"
            required
            hide-details
            class="control"
            ref="confirmPassword"
            outlined
          />
        </v-expand-transition>
        <v-expand-transition>
          <v-checkbox
            v-show="firstAccess"
            v-model="tos.accepted"
            color="primary"
            class="pt-4"
          >
            <span 
              slot="label" 
              class="body-2"
            >
              Li e aceito os 
              <a
                href="#terms"
                @click.prevent.stop="tos.toggle = true"
              >Termos e Condições</a>
              e a
              <a
                href="#privacy"
                @click.prevent.stop="privacy.toggle = true"
              >Política de Privacidade</a>.
            </span>
          </v-checkbox>
        </v-expand-transition>

        <v-expand-transition>
          <v-text-field
            v-show="(input.ghost.toggle&&!ghosted)||(status!='REG'&&status!=null&&!passwordRecovery&&!ghosted)"
            v-model="input.password.input"
            :append-icon="input.password.visible ? icons.mdiEye : icons.mdiEyeOff"
            :type="input.password.visible ? 'text' : 'password'"
            :disabled="input.password.disabled"
            @click:append="input.password.visible = !input.password.visible"
            @keyup.enter="handleForm"
            hide-details
            id="password"
            label="Senha"
            required
            class="control my-4"
            ref="password"
            outlined
          />
        </v-expand-transition>
        <v-expand-transition>
          <v-text-field
            v-show="input.ghost.toggle&&input.ghost.tfa&&!ghosted"
            v-model="input.ghost.input"
            :disabled="input.ghost.disabled"
            @keyup.enter="handleForm"
            hide-details
            id="otp"
            label="OTP"
            required
            class="control my-4"
            ref="otp"
            outlined
          />
        </v-expand-transition>

        <v-expand-transition>
          <v-btn
            v-show="enableSubmit"
            :disabled="!enableSubmit"
            :elevation="0"
            :loading="submit.loading"
            block
            large
            class="control black--text"
            color="primary"
            @click="handleForm"
          >
            {{ submit.text }}
          </v-btn>
        </v-expand-transition>

        <v-btn 
          text 
          block
          v-show="status&&status!='REG'&&!passwordRecovery"
          @click="togglePasswordRecovery(true)"
          class="forgot-btn mb-2 primary--text"
        >
          Esqueci a senha
        </v-btn>
        <v-btn 
          text
          block
          @click.stop="toggleHelp(true, 'access')"
          class="help-btn my-2 grey--text"
        >
          <!-- <v-icon>{{ icons.mdiHelpCircleOutline }}</v-icon> -->
          Precisa de ajuda?
        </v-btn>
      </v-form>
    </v-scroll-y-transition>

    <forgot-password />

    <tos />

    <privacy-policy />

    <help 
      v-if="helpContent!=null"
      :toggle="help.toggle"
      :content="helpContent"
    />

    <v-dialog
      v-model="dialogs.validation.toggle"
      overlay-opacity=".8"
      transition="slide-y-reverse-transition"
    >
      <v-card color="black">
        <v-card-title
          v-if="dialogs.validation.sms"
        >
          Confirmação de telefone
        </v-card-title>
        <v-card-title
          v-else
        >
          Confirmação de email
        </v-card-title>
        <v-card-text class="py-4">
          <p
            v-if="dialogs.validation.sms"
            class="body-1">
            Enviamos um <b>SMS com código de confirmação para o número {{ phone }}</b>.
          </p>
          <p
            v-else
            class="body-1">
            Enviamos um <b>código de confirmação para o email {{ email }}</b>. Verifique seu email.
          </p>

          <v-text-field 
            v-model="dialogs.validation.input" 
            :label="dialogs.validation.label" 
            :loading="dialogs.validation.loading"
            :error="dialogs.validation.valid==false"
            v-mask="dialogs.validation.mask"
            type="tel" 
            color="primary"
            class="mt-6"
            outlined
            hide-details
            @keydown.enter="validateCode()"
          />

          <p
            v-if="dialogs.validation.sms"
            class="body-2 text--secondary">
            Caso ainda não tenha recebido o código, <a @click="toggleHelp(true, 'access')">acesse nossa central de ajuda</a>.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey"
            text
            :disabled="dialogs.validation.loading"
            @click="toggleHelp(true, 'access')"
          >
            Ajuda
          </v-btn>
          <v-spacer />
          <!-- <v-btn
            v-if="!enableCodeValidation"
            color="primary darken-2"
            text
            :loading="dialogs.validation.loading"
            @click="sendSMS"
          >
            Não recebeu?
          </v-btn> -->
          <v-btn
            color="primary"
            text
            :disabled="dialogs.validation.loading"
            @click="validateCode()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogs.deleteAccount.toggle"
      transition="slide-y-reverse-transition"
      overlay-opacity=".8"
      persistent
    >
      <v-card color="black">
        <v-card-title>Cancelar seu cadastro?</v-card-title>
        <v-card-text class="py-4">
          <p class="body-2">
            Para apagar seu cadastro na Mobees, digite <b>apagar</b> e clique no botão Confirmar.
          </p>
          <p class="body-2">
            <b>Atenção: Tem certeza disso? Todos seus dados serão apagados.</b>
          </p>

          <v-text-field 
            v-model="dialogs.deleteAccount.input" 
            :label="dialogs.deleteAccount.label" 
            :loading="dialogs.deleteAccount.loading"
            color="primary"
            @keydown.enter="deleteAccount"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey"
            text
            @click="dialogs.deleteAccount.toggle = false;"
          >
            Voltar
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            :disabled="dialogs.deleteAccount.input!='apagar'"
            :loading="dialogs.deleteAccount.loading"
            @click="deleteAccount"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<style>

  #login {
    width: 100%;
    /* background: var(--driver-bg) !important; */
  }

  #login .jumbo {
    /* height: 40vh; */
    width: 100%;
  }

  #login .heading {
    letter-spacing: -.25px !important;
    line-height: 1.5;
  }

  #login .hive {
    height: 52px;
    min-height: 52px;
  }
  #login .hive:active {
    opacity: .8;
  }

  #login .logo {
    height: 26px;
    min-height: 26px;
  }

  #login .login-form {
    width: 100%;
    padding: 0 12px;
  }
  .control {
    margin: 16px 0;
  }

  #login .help-btn.fab {
    position: fixed;
    bottom: 0;
    right: 0;
    text-transform: initial;
    width: 72px;
    height: 72px;
    margin: 0 -8px -8px 0 !important;
    opacity: .5;
  }
</style>

<script>

  // icons
  import {
    mdiHelpCircleOutline,
    mdiEye,
    mdiEyeOff,
    mdiClose,
    mdiGhostOutline
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync } from 'vuex-pathify'
  import { mask } from 'vue-the-mask'
  import _ from 'lodash';


  export default {
    name: 'Login',

    props: {
      installed: {
        type: Boolean,
        default: true,
      },
      pwa: {
        type: Boolean,
        default: true,
      },
      show: {
        type: Boolean,
        default: true,
      },
      modal: {
        type: String,
        default: null
      },
      passwordRecovery: {
        type: Boolean,
        default: false,
      },
      routeTos: {
        type: Boolean,
        default: false,
      },
      routePrivacy: {
        type: Boolean,
        default: false,
      },
      routeCancel: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      ForgotPassword: () => import('@/views/login/ForgotPassword'),
      Tos: () => import('@/views/help/tos'),
      PrivacyPolicy: () => import('@/views/help/PrivacyPolicy'),
      Help: () => import('@/views/help/Help'),
      MBtnLong: () => import('@/components/mBtnLong'),
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiEye,
        mdiEyeOff,
        mdiClose,
        ghost: mdiGhostOutline
      },
      valid: false,
      lazy: true,
      input: {
        cpf: {
          mask: '###.###.###-##',
          loading: false,
          disabled: false,
          exists: null
        },
        ghost: {
          toggle: false,
          username: null,
          tfa: false,
          otp: null,
          entity: null,
          loading: false,
          disabled: false,
        },
        email: {
          loading: false,
          disabled: false,
          exists: null
        },
        password: {
          input: '',
          disabled: false,
          loading: false,
          visible: false,
        },
        newPassword: {
          new: '',
          confirm: '',
          rules: [
          v => (!!v && v) === this.input.newPassword.new || 'A confirmação deve ser igual à senha.'
          ],
          disabled: false,
          loading: false,
          visible: false,
        },
      },
      submit: {
        text: 'Próximo',
        loading: false,
        disabled: true
      },
      dialogs: {
        validation: {
          toggle: false,
          loading: false,
          disabled: true,
          input: null,
          mask: '######',
          label: 'Digite o código',
          valid: null,
          sms: false
        },
        deleteAccount: {
          toggle: false,
          loading: false,
          disabled: true,
          input: null,
          label: 'Digite apagar para confirmar'
        },
      },
      help: {
        toggle: false,
      },
      transition: {
        start: false,
        assets: ['view', 'symbol', 'logo']
      },
      appVersion: APP_VERSION,
      appURL: process.env.VUE_APP_ROOT,
      helpContent: null
    }),

    computed: {
      views: sync('app/views'),
      // help: sync('app/views@help'),
      tos: sync('app/views@tos'),
      privacy: sync('app/views@privacy'),
      help_sections: sync('help/sections'),
      toast: sync('app/toast'),
      email: sync('user/profile@email'),
      fullname: sync('user/profile@fullname'),
      phone: sync('user/profile@phone'),
      version: sync('app/version'),
      ...sync('user/*'),

      largeHeading () {
        var b;
        if(this.firstAccess||this.passwordRecovery){
          b = false;
        }else{
          b = true;
        }
        return b;
      },

      heading (){
        var text;
        if(this.firstAccess||this.passwordRecovery){
          const fullname = this.fullname;
          const name = fullname == null ? '' : fullname.split(' ')[0];
          text = fullname==null ? 'Crie sua nova senha:' : `Oi ${name}!<br> Vamos criar sua senha:`;
        }else{
          text = 'Seu carro ganha <span class="primary--text">upgrade</span>. <br>Sua vida também.'
        }
        return text;
      },

      firstAccess () {
        return this.status=='REG' && this.auth.code && this.auth.valid;
      },

      enableSubmit () {
        // other validations TODO
        var enable = this.input.ghost.toggle || false;
        if (!enable) {
          if (this.passwordRecovery){
            var a = this.validateCPF();
            var b = this.validateNewPassword();
            enable = (a&&b) ? true : false;
          }else{
            switch (this.status){
              case 'REG':
                if (this.firstAccess) {
                  var a = this.validateCPF();
                  var b = this.validateNewPassword();
                  var c = this.tos.accepted;
                  enable = (a&&b&&c) ? true : false;
                }else{
                  enable = this.validateCPF();
                }
                break;
              case null:
                let d;
                if (this.input.cpf.exists==false) {
                  d = this.validateEmail();
                }else{
                  d = this.validateCPF();
                }
                enable = d;
                break;
              default:
                var a = this.validateCPF();
                var b = this.validatePassword();
                enable = this.cpf!=null ? (a&&b) : a;
                break;
            }
          }
        }

        return enable;
      },

      enableCodeValidation () {
        const code = this.dialogs.validation.input;
        return code != null && code.length==6;
      },

      ghosted () {
        return !_.isNil(this.input.ghost.entity);
      }
    },

    watch: {
      modal: {
        immediate: true,
        handler (view) {
          this.help.toggle = view=='help';
        }
      },
      helpContent: {
        immediate: true,
        deep: true,
        handler (content) {
          if (!!content&&_.has(content.controller, 'maintenance')&&content.controller.maintenance) {
            this.$router.replace({ path: '/maintenance' });
          }
        }
      }
    },

    methods: {
      ...services,

      getRecoveryToken () {
        // get recovery token if present
        return typeof this.$route.params.resetToken!=='undefined' ? this.$route.params.resetToken : null
      },
      getRecoveryCPF () {
        // get recovery CPF if present
        return typeof this.$route.params.cpf!=='undefined' ? this.$route.params.cpf : null
      },
      getCPF () {
        // get recovery CPF if present
        return typeof this.$route.query.cpf!=='undefined' ? this.$route.query.cpf : null
      },
      getCancelToken () {
        // get cancel token if present
        return this.routeCancel && typeof this.$route.query.token!=='undefined' ? this.$route.query.token : null
      },

      togglePasswordRecovery: function (b) {
        this.views.forgotPassword.toggle = b;

        // this.trackPage('forgotPassword');
      },

      handleForm () {
        if (this.ghosted) {
          this.ghostAccess(this.input.ghost.entity.username, this.input.ghost.entity.auth.token, this.rawCPF(this.cpf));
        }else if (!this.input.ghost.toggle) {
          if (this.passwordRecovery) {
            // Create password
            this.submit.loading = true;
            this.createPassword(this.rawCPF(this.cpf), this.input.newPassword.confirm);
          }else if (this.status==null||this.status=='REG') {
            if (this.firstAccess) {
              this.submit.loading = true;
              this.createPassword(this.rawCPF(this.cpf), this.input.newPassword.confirm, true);
            }else if (this.input.cpf.exists==false&&this.input.email.exists==false) {
              window.open('https://www.mobees.com.br/dirija?utm_source=app_parceiros');
            }else if (this.input.cpf.exists==false&&this.validateEmail()) {
              this.submit.loading = true;
              this.checkEmail(this.email);
            }else if (this.validateCPF()) {
              this.submit.loading = true;
              this.checkCPF(this.rawCPF(this.cpf));
            }
          }else{
            // Login password
            if(this.input.password.input.length){
              this.submit.loading = true;
              this.login(this.rawCPF(this.cpf), this.input.password.input);
            }else{
              this.$refs.password.$el.focus();
            }
          }
        }else{
          this.ghostAuth(this.input.ghost.username, this.input.password.input, this.input.ghost.otp);
        }
      },

      validateCPF () {
        return this.cpf ? this.cpf.length===14 : false;
      },
      validateEmail () {
        const pattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return pattern.test(this.email);
      },
      validatePassword () {
        return this.input.password.input ? this.input.password.input.length>=3 : false;
      },
      validateNewPassword () {
        const a = this.input.newPassword.new===this.input.newPassword.confirm;
        const b = (this.input.newPassword.new!='');
        return a&&b;
      },
      rawCPF (cpf) {
        return cpf.replace(/^[.-]+|[.-]+$|[.-]+/g, "");
      },
      checkCPF (cpf) {
        console.log('checking cpf status...');

        this.$api
          .get('/statuscpfdriver/'+cpf+'/?format=json')
          .then(response => {
            console.log('cpf status => ',response);
            if(response.data.retorno==200){
              // Store CPF status
              this.status = response.data.status; 
              this.statusChange = response.data.dt_Status; 
              this.email = response.data.email_driver;
              this.phone = response.data.wpp_driver;

              if (response.data.status=='REG') {
                if (response.data.email_enviado==1) {
                  this.handleStatus(this.status);
                }else{
                  this.toggleToast(
                    true,
                    'Ops, deu eu erro aqui! Tente novamente, por favor.',
                    5000
                  );
                }
              }else{
                this.handleStatus(this.status);
              }
              console.log('status',this.status);
            }else{
              // handle CPF not found
              this.input.cpf.exists = false;
              
              this.toggleToast(
                true,
                'CPF não encontrado. Digite o email cadastrado.',
                10000
              );
              this.trackEvent('access', 'unregistered CPF handled');
            }
          })
          .catch(error => {
            this.handleError(error, null, true);
          })
          .finally(() => {
            this.submit.loading = false;
          });
      },
      checkEmail (email) {
        console.log('checking email status...');

        this.$api({
          url: '/statusemaildriver',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              email: email,
            }
          })
          .then(response => {
            console.log('/checkEmail => ',response);
            if(response.data.retorno==200){
              // Store CPF status
              this.status = response.data.status; 
              this.statusChange = response.data.dt_Status; 

              if (response.data.status=='REG') {
                if (response.data.email_enviado==1) {
                  this.handleStatus(this.status);
                }else{
                  this.toggleToast(
                    true,
                    'Ops, deu eu erro aqui! Tente novamente, por favor.',
                    5000
                  );
                }
              }
              
              console.log('status',this.status);
            }else{
              // handle Email not found
              this.input.email.exists = false;
              this.submit.text = 'Pré-cadastro';
              this.toggleToast(
                true,
                'CPF/Email não cadastrados. Acesse nosso site e faça seu pré-cadastro!',
                10000
              );
              this.trackEvent('access', 'unregistered user handled');
            }
          })
          .catch(error => {
            this.handleError(error, null, true);
          })
          .finally(() => {
            this.submit.loading = false;
          });
      },
      resetForm () {
        this.cpf = null;
        this.status = null;
        this.email = null;
        this.code = null;
        this.input.cpf.exists = null;
        this.input.email.exists = null;
      },
      validateCode () {
        console.log('validating code...');
        this.dialogs.validation.loading = true;

        const email = _.isNil(this.email) ? '' : this.email;
        const cpf = this.rawCPF(this.cpf);
        const code = this.dialogs.validation.input;

        this.$api({
          url: '/validatokenregdriver',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              cpf: cpf,
              email: email,
              tokenReg: code
            }
          })
          .then(response => {
            console.log('/verifyCodeDriver => ',response);
            if(response.data.retorno==200){
              // Store CPF status
              this.status = response.data.status; 
              this.auth.code = response.data.token_reg; 
              this.auth.valid = true; 
              this.fullname = response.data.nm_motorista; 
              this.dialogs.validation.valid = true;
              this.dialogs.validation.toggle = false;
              
              this.handleStatus(this.status);
              
            }else if(response.data.retorno==401){
              // handle wrong token
              this.dialogs.validation.valid = false;
              this.toggleToast(
                true,
                'Código de confirmação incorreto.',
                10000
              );
              this.trackEvent('access', 'email validation token error');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.dialogs.validation.loading = false;
          });
      },
      handleStatus (status) {
        if (status=='REG') {
          if (this.dialogs.validation.valid) {
            this.$nextTick(() => this.$refs.newPassword.$el.querySelector('input').focus());
          }else{
            this.dialogs.validation.toggle = true;
          }
        }else if (status==null) {
          this.toggleToast(
            true,
            'Acesse mobees.com.br/dirija e faça seu pré-cadastro!',
            -1
          );
          this.trackEvent('access', 'unregistered handled');
        }else if (status=='DEN') {
          this.toggleToast(
            true,
            'Acesso bloqueado.',
            -1
          );
          this.trackEvent('access', 'blocked user attempt');
        }else{
          this.$nextTick(() => this.$refs.password.$el.querySelector('input').focus());
        }
      },
      createPassword (cpf, password, accepted_terms=false) {
        // Save password on db
        cpf = this.rawCPF(cpf);
        let resetToken = this.getRecoveryToken()!=null ? this.getRecoveryToken() : '';
        const code = this.auth.code;

        this.$api({
          url: '/createpassworddriver',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              cpf: cpf,
              senha: password,
              resetTk: resetToken,
              token_reg: code,
              accepted_terms
            }
          })
          .then(response => {
            console.log('createPassword => ', response);
            if(response.data[0].retorno==201){
              this.status = response.data[0].status;
              this.statusChange = response.data[0].dt_status;

              if (!!this.email&&this.email.indexOf('*')>0) this.email = null;
              if (!!this.phone&&this.phone.indexOf('*')>0) this.phone = null;

              if (this.passwordRecovery) {
                this.$router.push('/');
              }else{
                this.login(cpf,password);
              }
              this.toggleToast(
                true,
                'Senha criada com sucesso!',
                5000,
              );
              this.trackEvent('password', 'set');
            }else{
              this.trackEvent('password', 'invalid recovery token');
              this.toggleToast(
                true,
                'Link de recuperação de senha inválido.',
                5000,
              );
              this.cpf = this.status = this.statusChange = null;
              this.$router.push('/');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.submit.disabled = this.submit.loading = false;
          });
      },

      login (cpf,password) {
        this.$api({
          url: '/authuserdriver',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              cpf: cpf,
              senha: password,
            }
          })
          .then(response => {
            console.log('login => ',response);
            if(response.data[0].retorno==200){
              const auth = Object.assign({}, {
                token: response.data[0].authToken,
                timestamp: new Date(),
                status: true,
              });
              this.auth = auth;
              const status = this.status;

              const user = { username: cpf, auth, status };
              this.setStorage('user', user);

              if (this.installed) {
                mobees.setUser(cpf, this.auth.token);
                window.plugins.OneSignal.setExternalUserId(cpf);
              }

              if (status=='ACT') {
                this.$router.push('/main');
              }else{
                this.$router.push('/signup');
              }
              this.trackEvent('access', 'login');
            }else{
              this.trackEvent('access', 'invalid credentials');
              this.toggleToast(
                true,
                'CPF e/ou senha inválidos. Verifique seus dados.',
                5000,
                false,
              );
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível acessar sua conta. Verifique sua conexão e tente novamente, por favor. ', true);
          })
          .finally(() => {
            this.submit.loading = false;
          });
      },

      ghostAuth (useradmin,password, otp) {
        this.submit.loading = true;
        this.$api({
          url: '/authuseradmin',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              useradmin,
              password,
              otptoken: otp
            }
          })
          .then(response => {
            console.log('ghostAuth => ', response);
            if (response.data.retorno==206){
              this.input.ghost.tfa = true;
            }else if (response.data.retorno==200){
              const entity = {
                username: useradmin,
                auth: {
                  token: response.data.authToken,
                  jwt: response.data.jwtToken,
                  timestamp: new Date(),
                  status: true
                },
                roles: _.map(response.data.perfis, role => role.id_perfil),
              };
              this.setStorage('ghost', entity);
              if (_.intersection(entity.roles, [1,2])) {
                this.input.ghost.entity = entity;
              }
            }else{
              this.trackEvent('access', 'invalid admin credentials');
              this.toggleToast(
                true,
                'Usuário e/ou senha inválidos',
                5000,
                false,
              );
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível acessar sua conta. Verifique sua conexão e tente novamente, por favor. ', true);
          })
          .finally(() => {
            this.submit.loading = false;
          });
      },

      ghostAccess (useradmin, token, cpf) {
        this.submit.loading = true;
        this.$api({
          url: '/driver/ghost-access',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              useradmin,
              token,
              cpf
            }
          })
          .then(response => {
            console.log('ghostAccess => ', response);
            const status = this.status = response.data.status; 
            this.statusChange = response.data.dt_Status; 
            this.email = response.data.email_driver;
            this.phone = response.data.wpp_driver;

            const auth = Object.assign(this.auth, {
              token: response.data.authtoken,
              timestamp: new Date(),
              status: true,
            });
            const user = { username: cpf, auth, status };
            this.setStorage('user', user);

            if (this.installed) {
              mobees.setUser(cpf, this.auth.token);
              window.plugins.OneSignal.setExternalUserId(cpf);
            }

            if (this.status=='ACT') {
              this.$router.push('/main');
            }else{
              this.$router.push('/signup');
            }
            this.trackEvent('access', 'login');
          })
          .catch(error => {
            this.handleError(error, 'Acesso negado', true);
          })
          .finally(() => {
            this.submit.loading = false;
          });
      },

      deleteAccount () {
        this.dialogs.deleteAccount.loading = true;
        this.$api({
          url: '/deleteaccountdriver',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              cpf: this.getCPF(),
              hash: this.getCancelToken()
            }
          })
          .then(response => {
            console.log('deleteAccount => ',response);
            if(response.data.retorno==200){
              this.trackEvent('account', 'deleted');
              this.dialogs.deleteAccount.toggle = false;
              this.toggleToast(
                true,
                'Seu cadastro foi cancelado com sucesso.',
                7000,
                false
              );
              this.getout();
            }else{
              this.trackEvent('account', 'invalid token');
              this.dialogs.deleteAccount.toggle = false;
              this.toggleToast(
                true,
                'Link de cancelamento inválido.',
                10000,
                false
              );
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.dialogs.deleteAccount.loading = false;
          });
      },

      sendSMS () {
        console.log('sending SMS...');
        this.dialogs.validation.loading = true;

        const cpf = this.rawCPF(this.cpf);

        this.$api
          .get('/statussmsdriver/'+cpf+'/?format=json')
          .then(response => {
            console.log('/sendSMS => ',response);
            if(response.data.retorno==200){
              // Store CPF status
              this.dialogs.validation.sms = true;
              this.dialogs.validation.toggle = true;
              this.auth.sms = true;
              
              this.trackEvent('access', 'SMS confirmation sent');
            }else{
              // handle Email not found
              this.toggleToast(
                true,
                'Não foi possível enviar SMS de confirmação. Fale com nosso Atendimento pelo email suporte@mobees.com.br',
                -1
              );
              this.trackEvent('access', 'SMS confirmation failed');
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível enviar SMS de confirmação. Fale com nosso Atendimento pelo email suporte@mobees.com.br', true);
          })
          .finally(() => {
            this.dialogs.validation.loading = false;
          });
      },

      toggleGhost (b) {
        b = _.isNil(b) ? !this.input.ghost.toggle : b;
        console.log('ghost', b);
        this.input.ghost.toggle = b;
        if (!b) {
          this.delStorage('ghost');
          this.input.ghost.entity = null;
        }
      }

    },

    created () {
      
    },

    mounted () {
      // set password recovery mode
      if (this.passwordRecovery) {
        this.cpf = this.getRecoveryCPF();
      }
      if (this.status=='REG'&&this.cpf!=null&&this.auth.valid==null) {
        this.dialogs.validation.toggle = true;
        this.dialogs.validation.sms = this.auth.sms;
      }
      if (this.routeTos) {
        console.log('TOS', this.routeTos);
        this.tos.toggle = true;
      }else{
        this.tos.toggle = false;
      }
      if (this.routePrivacy) {
        console.log('Privacy Policy', this.routePrivacy);
        this.privacy.toggle = true;
      }else{
        this.privacy.toggle = false;
      }
      if (this.routeCancel) {
        console.log('Cancel Account', this.routePrivacy);
        this.dialogs.deleteAccount.toggle = true;
      }else{
        this.dialogs.deleteAccount.toggle = false;
      }

      this.loadHelpContent(false);

      this.getStorage('ghost').then((value) => {
        this.input.ghost.entity = value;
        this.$nextTick(() => {
          this.toggleGhost(this.ghosted);
        })
      });

      // if (this.installed) {
      //   this.setAppVersion(this.appVersion);
      // }

      this.cancelToasts();
    },

    directives: {
      mask,
    },

  }
</script>
